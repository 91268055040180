import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

const CourseCard = (props) => {
  const edge = props.edge;
  const wholePrice = edge.node.frontmatter.price.split(".")[0];
  const fractionPrice = edge.node.frontmatter.price.split(".")[1];
  return (
    // todo: can you not copy paste this shit twoice goddammit ??
    <div className="card-deck">
      <Link
        to={edge.node.frontmatter.detailPagePath}
        className="card clickable-card"
      >
        <Img
          className="card-img-top"
          fluid={edge.node.frontmatter.image.childImageSharp.fluid}
          alt={edge.node.frontmatter.title}
        />
        <div className="card-body">
          <h4 className="card-title">
            <Link to={edge.node.frontmatter.detailPagePath}>
              {edge.node.frontmatter.title}
            </Link>
          </h4>
          <p className="card-text">
            <Link to={edge.node.frontmatter.detailPagePath}>
              {edge.node.frontmatter.level}
            </Link>
          </p>
          <span className="card-pricing">
            <span>{wholePrice}</span>
            {fractionPrice ? (
              <span className="fraction-price">{fractionPrice}</span>
            ) : (
              <></>
            )}
          </span>

          {/*
          <p className="card-text text-muted text-wrap">
            <Link to={edge.node.frontmatter.path}>
            {edge.node.frontmatter.excerpt}
            </Link>
          </p>
          */}
        </div>
        <div className="card-footer">
          <button className="pl-0">
            <Link
              className="card-link text-muted text-small"
              to={edge.node.frontmatter.detailPagePath}
            >
              View course
            </Link>
          </button>
        </div>
      </Link>
    </div>
  );
};

export default CourseCard;
