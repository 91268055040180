import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import TitleBanner from "../components/TitleBanner";
import CourseCard from "../components/CourseCard";
import ProductItem from "../components/ProductItem";
import MailChimpBanner from "../components/Mailchimp-banner";
import SmallCardBlog from "../components/SmallCardBlog";
import Link from "gatsby-link";
import ContentBanner from "../components/ContentBanner";
import PromoBanner from "../components/PromoBanner";
import LHTBanner from "../components/LHTBanner";

const Home = (props) => {
  const site = props.data.site.siteMetadata;
  const shopItems = props.data.allShopJson.edges;
  const allPosts = props.data.allMdx.edges;

  const lessonAndCoursesPosts = allPosts.filter(
    (post) => post.node.frontmatter.category === "Courses"
  );

  const courses = [];

  for (const { node: course } of props.data.allCoursesJson.edges) {
    // make sure that the order is based on the order in the json file not the markdown files
    const matchingCourse = lessonAndCoursesPosts.find(
      (post) => post.node.frontmatter.detailPagePath === course.detailPagePath
    );
    if (matchingCourse) {
      courses.push(matchingCourse);
    }
  }

  const filteredShopItems = shopItems.filter((item) => {
    return !item.node.noIndex;
  });
  const homePageBlogs = allPosts
    .filter(
      (post) =>
        post.node.frontmatter.category !== "Courses" &&
        post.node.frontmatter.category !== "Lessons"
    )
    .splice(0, 6);
  return (
    <Layout bodyclassName="page-home">
      <SEO
        description={site.description}
        title="Learn Southern Vietnamese with HowToVietnamese | Free Vietnamese Courses and eBooks"
      />
      {/*  Banner */}
      <PromoBanner />
      <TitleBanner className="col-12" />
      {/* BOOK SELECTION */}
      {/* <div className="container">    
       <div className="row d-flex justify-content-center">  
       <div className="col-11">  
        <div className="row reverse-flex-mobile pt-4 pt-md-8 pb-4 pb-md-6">
          <div className="col-12 col-md-6 mb-4">
            <div>
              <h2>Learn sentence building & essential vocabulary to boost your learning</h2>
              <p>These books not only give a strong foundation of Vietnamese language, but also help you become conversational as quickly as possible. All books and lessons focus on spoken Southern Vietnamese so you can learn to speak naturally like the locals.</p>
              <br />
              <Link className="btn-outline" to="/store">Go to Store > </Link>
            </div>
          </div>
          <div className="col-12 col-md-6 book-selection-image mb-4">
            <img src={BookSelection} alt="book selection"></img>
          </div>

        </div>

        

        <div className="row pb-4 pb-md-10">
          <div className="col-12 col-md-6 pr-md-3 mb-2">
            <img src={LessonSelection} alt="lesson selection"></img>
          </div>
          <div className="col-12 col-md-6">
            <div>
              <h2>Start learning for FREE from our collection of lessons & courses.</h2>
              <p>Get quick lessons if you want to take it easy and have a 20-minute bite-sized topic lesson. Enroll in mini courses to improve all skills and learn authentic Vietnamese at your own pace with selective audio & video lessons.</p>
              <br />
              <Link className="btn-outline" to="/beginner">Get Started > </Link>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>  */}
      {/* END CONTAINER  */}
      {/* <div className="ml-sm-2 ml-md-0 mr-sm-2 mr-md-0">
        <MailChimpBanner />
      </div> */}
      {/* SOCIAL PLATFORMS  */}
      <ContentBanner></ContentBanner>
      {/* END CONTAINER  */}
      <div className="container pt-6 pt-md-8 pb-4 pl-2 pr-2 pl-md-0 pr-md-0 d-flex justify-content-center">
        <div className="col-12 col-md-11">
          {/*COURSES */}
          {courses.length ? (
            <div className="row mt-2 mb-10">
              <div className="row mb-2">
                <h1 className="pl-1 pr-1">Courses</h1>
              </div>
              <div className="row">
                {courses.map((edge, i) => (
                  <div className="col-sm-12 col-md-4 mb-md-4 mb-1">
                    <CourseCard edge={edge} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* BOOKS */}
          {filteredShopItems.length ? (
            <React.Fragment>
              <div className="row mb-10">
                <div className="row mb-2">
                  <h1 className="pl-1 pr-1">Books</h1>
                </div>
                <div className="row">
                  {filteredShopItems.map((item) => (
                    <div className="col-sm-12 col-md-4 mb-md-4 mb-1">
                      <ProductItem props={item}></ProductItem>
                    </div>
                  ))}
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {/* BLOGS */}
          {homePageBlogs.length ? (
            <div className="row mb-10">
              <div className="row mb-2">
                <h1 className="pl-1 pr-1">Latest Articles</h1>
              </div>
              <div className="row">
                {homePageBlogs.map((edge, i) => (
                  <div className="col-sm-12 col-md-4 mb-md-4 mb-1">
                    <SmallCardBlog key={i} edge={edge} />
                  </div>
                ))}
              </div>
              <Link className="btn-outline align-center" to="/blog">
                View All
              </Link>
            </div>
          ) : null}
        </div>
      </div>{" "}
      {/* END CONTAINER*/}
      <div className="col-12 bg-white pt-2">
        <MailChimpBanner />
      </div>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        contact {
          email
          phone
          podcast
          facebook
          youtube
        }
      }
    }

    allMdx(
      sort: { fields: [frontmatter___date], order: DESC } #  filter: { #    frontmatter: { #      level: { #        in: [$level] #      } #    } #  }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            detailPagePath
            title
            price
            subtitle
            category
            level
            excerpt
            date(formatString: "DD MMMM YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    allShopJson {
      edges {
        node {
          title
          description
          image
          detailPagePath
          price
          level
        }
      }
    }
    allCoursesJson {
      edges {
        node {
          coursePath
          detailPagePath
          extraInfo
          price
          type
          purchaseLinks {
            label
            link
            isOutbound
          }
        }
      }
    }
  }
`;
