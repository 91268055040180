import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const SmallCardBlog = (props) => {
  const { edge } = props;
  return (
    <div className="card-deck pb-0 pb-md-1">
      <Link className="card clickable-card" to={edge.node.frontmatter.path}>
        <Img className="card-img-top"
          fluid={edge.node.frontmatter.image.childImageSharp.fluid}
          alt={edge.node.frontmatter.title}
        />
      <div className="card-body">
        <h6 className="card-text text-small text-blue">{edge.node.frontmatter.category}</h6>
        <h5 className="card-title">
          <Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link>
        </h5>
        <p className="card-text text-muted text-wrap">{edge.node.frontmatter.excerpt}</p>
      </div>
      <div className="card-footer">
        <button>
          <Link className="card-link text-muted text-small" to={edge.node.frontmatter.path}>
            READ MORE
          </Link>
        </button>
      </div>
      {/*
          <button className="btn btn-primary">
            <Link to={edge.node.frontmatter.path}>Read More</Link>
          </button>


          <div className="mb-1">
            <span className="small-card-category">
              {edge.node.frontmatter.category}
            </span>
             <span className="entry-date">{edge.node.frontmatter.date}</span>
          </div> */}
      </Link>

    </div>
  );
};

export default SmallCardBlog;
