import React from "react";
import HomepageImage from "../images/homepage-img.png";
const TitleBanner = () => {
  return (
    <div
      className="contentContainer jumbotron jumbotron-fluid margin-from-header bg-white mb-0 pt-md-8 pb-md-8"
      id="home-banner"
    >
      <div className="container d-flex justify-content-center pl-md-0 pr-md-0 ">
        <div className="col-12 col-md-11">
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-6 text-left flex-column d-flex justify-content-center">
              <h1>
                Learn how to{" "}
                <span className="highlight-underline">
                  Read, Speak, Express
                </span>{" "}
                in Vietnamese
              </h1>
              <p className="banner-text text-muted pt-1">
                Kick-start your language goal this year and learn Vietnamese
                anywhere, anytime with quick online lessons and practical
                eBooks, all in Southern dialect!
              </p>
              <br />
              {/* <div>
                <button className="pl-0 align-left">
                  <Link to="/vietnamese-class" className="btn btn-solid">
                    View course
                  </Link>
                </button>
              </div> */}
            </div>

            <div className="col-md-6 hide-on-mobile">
              <img src={HomepageImage} className="homeBanner"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleBanner;
