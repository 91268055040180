import { OutboundLink } from "gatsby-plugin-google-analytics";
import React from "react";

const ProductItem = (props) => {
  console.log(props);
  const item = props.props.node;
  const wholePrice = item.price.split(".")[0];
  const fractionPrice = item.price.split(".")[1];
  return (
    <div className="card-deck">
      <div className="card clickable-card">
        <OutboundLink href={item.detailPagePath}>
          <div className="product-image-background image-sm card-img-top">
            <img className="shop-image" src={item.image} alt={item.title} />
          </div>
          <div className="card-body">
            <h4 className="card-title">{item.title}</h4>
            <p className="card-text">{item.level}</p>
            <span className="card-pricing">
              <span>{wholePrice}</span>
              {fractionPrice ? (
                <span className="fraction-price">{fractionPrice}</span>
              ) : (
                <></>
              )}
            </span>
          </div>
          <div className="card-footer">
            <button className="pl-0">
              <OutboundLink
                className="card-link text-muted text-small"
                href={item.detailPagePath}
              >
                View details
              </OutboundLink>
            </button>
          </div>
        </OutboundLink>
      </div>
    </div>
  );
};

export default ProductItem;
